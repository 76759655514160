import { withTranslation, useTranslation } from "react-i18next";
import DemoLabel from "../../components/demoLabel/demoLabel";
import DataLayer from "../../analytics/dataLayer";
import LogoWhite from "../../assets/icons/logoWhite.svg";

import "./errorPage.scss";

const ErrorPage = ({ errorStatusCode, correlationId, loading }) => {
  const { t } = useTranslation();
  const correlationID = correlationId.slice(24, 36);
  return (
    <>
      <DataLayer watchData="" status={errorStatusCode} loading={loading} />
      <DemoLabel />
      <div className="errorScreen">
        <img className="logoWhite" src={LogoWhite} alt={t("accessibility.logoImgAlt")} />
        <div className="textWrapper">
          <div className="smallTitle">
            {
              errorStatusCode === 500
                ? t("authenticationServiceUnreachable")
                : errorStatusCode === 400
                ? t("unableToReadCard")
                : errorStatusCode === 409
                ? t("cardSingleUsage") 
                : errorStatusCode === 423 ?
                  t("cardNoLongerAssociated")
                : t("unableToReadCard")
            }
          </div>
          <div className="errCode">
            <span>
              {
                errorStatusCode === 500
                  ? "ERR01"
                  : errorStatusCode === 400
                  ? "ERR02"
                  : errorStatusCode === 409
                  ? "WARNING03"
                  : errorStatusCode === 423 
                  ? "INFO01"
                  : "ERR02"
              }
            </span>
            <span>{correlationID}</span>
          </div>
        </div>
      </div>
      <DataLayer pageBottom />
    </>
  );
};

export default withTranslation()(ErrorPage);
