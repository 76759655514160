import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import Accordion from "../accordion/accordion";
import Exclusion from "./exclusion";
import { getServiceString } from "./utils";
import "./services.scss";

function Services({ services }) {
  const { t } = useTranslation();
  return (
    <>
      <Accordion title={t("servicesHeading")}>
        <div className="servicesWrapper">
          {services && services[0]?.ServiceType ? (
            <>
              <div className="heading">{t("serviceType")}</div>
              <div className="description">
                {getServiceString(services[0]?.ServiceType, t)}
              </div>
            </>
          ) : (
            ""
          )}

          <Exclusion services={services} />
        </div>
      </Accordion>
    </>
  );
}

export default withTranslation()(Services);
