import React from "react";
import { withTranslation, useTranslation } from "react-i18next";

function Exclusion({ services }) {
  function counterService(services, stringToCompare) {
    //count how many services have "N" or "Y"
    let counter = 0;
    if (
      services[0]?.WarrantyExclBra === stringToCompare ||
      !services[0]?.WarrantyExclBra
    )
      counter += 1;
    if (
      services[0]?.WarrantyExclPrf === stringToCompare ||
      !services[0]?.WarrantyExclPrf
    )
      counter += 1;
    if (
      services[0]?.WarrantyExclWpr === stringToCompare ||
      !services[0]?.WarrantyExclWpr
    )
      counter += 1;

    return counter;
  }

  const { t } = useTranslation();
  return (
    <>
      {counterService(services, "N") === 3 ? null : (
        <>
          <div className="heading">{t("serviceExclusion")}</div>
          {services[0]?.WarrantyExclBra === "Y" && (
            <div className="description">{t("serviceBracelet")}</div>
          )}
          {services[0]?.WarrantyExclPrf === "Y" && (
            <div className="description">{t("servicePerformance")}</div>
          )}
          {services[0]?.WarrantyExclWpr === "Y" && (
            <div className="description">{t("serviceWaterproofness")}</div>
          )}
        </>
      )}
    </>
  );
}

export default withTranslation()(Exclusion);
