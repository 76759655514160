import LoaderWhite from "../../assets/icons/loader-white.gif";
import "./loadingPage.scss";

function Loading() {
  return (
    <div className="loading">
      <img className="loaderWhite" src={LoaderWhite} alt="logo" />
    </div>
  );
}

export default Loading;
