import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useTrackEvent,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin, appInsights } from "../../analytics/appInsights";
import { useSearchParams } from "react-router-dom";
import { preferedLanguage } from "../languageSwitcher/preferedLanguage";
import configData from "../../config.json";
import { useTranslation } from "react-i18next";
import { updateLanguageCode } from "../../utils/helpers/functions.helper";

function DataFetch({
  setIsError,
  setErrorStatusCode,
  setCorrelationId,
  setMessage,
  watchData,
  setWatchData,
  setbusinessData,
  setLoading,
  setAssociation,
  setGeneratedCode
}) {
  const navigate = useNavigate();
  const trackFetching = useTrackEvent(appInsights, "Fetch");
  const [token, setToken] = useState(sessionStorage.getItem("bearer"));
  const [searchParams, setSearchParams] = useSearchParams();
  const i18nextLng = localStorage.getItem("i18nextLng");
  const { i18n } = useTranslation();


  useEffect(() => {
    findSelectedLanguage(i18nextLng);
  }, [i18nextLng])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ekid = params.get("ekid");
    const enpd = params.get("enpd");
    const enfd = params.get("enfd");
    const cmac = params.get("cmac");
    const rid = params.get("rid");
    const p = params.get("p");
    const c = params.get("c");
    const m = params.get("m");
    const originalUrl = params.get("original_url");
    const url1 = `${configData.azureFunctionEndpoint}/Authenticate/v1?ekid=${ekid}&enpd=${enpd}&enfd=${enfd}&cmac=${cmac}&subscription-key=${configData.subscriptionkey}`;
    const url2 = `${configData.azureFunctionEndpoint}/Authenticate/v2?rid=${rid}&p=${p}&c=${c}&m=${m}&subscription-key=${configData.subscriptionkey}&original_url=${originalUrl}`;
    const cardType = params.get("type");
    // console.log('language', window.navigator.language);
    const fetchTokenUrl = !ekid ? url2 : url1;
    trackFetching({});
    if (
      !token &&
      (ekid === null || enpd === null || enfd === null || cmac === null) &&
      (rid === null ||
        p === null ||
        c === null ||
        m === null ||
        originalUrl === null)
    ) {
      window.location.replace(configData.redirectUrl);
    }

    if (
      (ekid !== null && enpd !== null && enfd !== null && cmac !== null) ||
      (rid !== null &&
        p !== null &&
        c !== null &&
        m !== null &&
        originalUrl !== null)
    ) {
      fetch(fetchTokenUrl)
        .then((response) => {
          if (response.status === 200) {
            for (const pair of response.headers.entries()) {
              if (pair[0] === "authorization") {
                sessionStorage.setItem("bearer", pair[1]);
                setToken(pair[1]);
              }
            }
            clearParams(ekid);
          } else {
            searchParams.delete("type");
            clearParams(ekid);
            for (const pair of response.headers.entries()) {
              if (pair[0] === "x-correlation-id") {
                setCorrelationId(pair[1]);
              }
            }
            setIsError(true);
            setErrorStatusCode(response.status);
            setLoading(false);
            return response.text();
          }
        })
        .then((data) => {
          if (data) {
            setMessage(data);
            setIsError(true);
            setLoading(false);
          }
        })
        .catch((e) => {
          setIsError(true);
          setLoading(false);
          console.log(e);
        });
    }
    const fetchWatchDataUrl = `${configData.azureFunctionEndpoint}/WatchData/?subscription-key=${configData.subscriptionkey}`;
    const fetchGenerateCodeUrl = `${configData.azureFunctionEndpoint}/GenerateCode/?subscription-key=${configData.subscriptionkey}`;

    if (token && ekid === null && rid === null) {
      fetch(fetchWatchDataUrl, {
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          searchParams.delete("type");
          setSearchParams(searchParams);
          if (response.status === 404) {
            if (cardType && cardType.toLowerCase() === "sc") {
              setAssociation(true);
              fetch(fetchGenerateCodeUrl, {
                headers: {
                  authorization: token,
                  "X-Brand": "ROLEX",
                  "X-CardType": "ServiceCard",
                },
              })
                .then((res) => {
                  setSearchParams(searchParams);
                  if (!res.ok) {
                    setAssociation(false);
                    setIsError(true);
                    for (const pair of res.headers.entries()) {
                      if (pair[0] === "x-correlation-id") {
                        setCorrelationId(pair[1]);
                      }
                    }
                    setErrorStatusCode(res.status);
                    setLoading(false);
                    return res.text();
                  }
                  setErrorStatusCode(res.status);
                  return res.json();
                })
                .then(
                  (data) => {
                    if (data) {
                      setGeneratedCode(JSON.parse(data));
                      setLoading(false);
                    }
                  },
                  (error) => {
                    console.log("generated code error", error);
                  }
                );
            }
          } else if (!response.ok) {
            searchParams.delete("type");
            setSearchParams(searchParams);
            setIsError(true);
            for (const pair of response.headers.entries()) {
              if (pair[0] === "x-correlation-id") {
                setCorrelationId(pair[1]);
              }
            }
            setErrorStatusCode(response.status);
            setLoading(false);
            return response.text();
          } else return response.json();
        })
        .then((data) => {
          if (data) {
            if (cardType) {
              searchParams.delete("type");
              setSearchParams(searchParams);
            }
            const responseData = JSON.parse(data);

            if (responseData.IsCpo) {
              responseData.IsCpo = false;
            }

            setWatchData(responseData);
            // setLoading(false);
          }
        })
        .catch((e) => {
          setIsError(true);
          setLoading(false);
          console.log(e);
        });
    }

    findSelectedLanguage()
  }, [token, navigate]);

  useEffect(() => {
    if (watchData !== null) {
      const rmc = watchData?.Configuration?.Rmc
        ? watchData?.Configuration?.Rmc
        : watchData?.Configuration?.Model;
      const fetchBusinessDataUrl = `${configData.azureFunctionEndpoint
        }/Translations?language=${updateLanguageCode(preferedLanguage())}&rmc=${rmc}&subscription-key=${configData.subscriptionkey
        }`;
      fetch(fetchBusinessDataUrl, {
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setbusinessData(JSON.parse(data));
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [token, watchData]);

  const clearParams = (ekid) => {
    if (ekid) {
      searchParams.delete("ekid");
      searchParams.delete("enpd");
      searchParams.delete("enfd");
      searchParams.delete("cmac");
    } else {
      searchParams.delete("rid");
      searchParams.delete("p");
      searchParams.delete("c");
      searchParams.delete("m");
      searchParams.delete("original_url");
    }
    setSearchParams(searchParams);
  };

  const findSelectedLanguage = (selectedLanguage) => {

    if (selectedLanguage) {
      switch (selectedLanguage.toLowerCase()) {
        case 'zh-tw':
        case 'zh-hk':
          localStorage.setItem("i18nextLng", "zh-Hant");
          i18n.changeLanguage("zh-Hant");
          break;
        case 'zh-cn':
        case 'zh':
          localStorage.setItem("i18nextLng", "zh-Hans");
          i18n.changeLanguage("zh-Hans");
          break;
        case 'en-ca':
          localStorage.setItem("i18nextLng", "en-us");
          i18n.changeLanguage("en-us");
          break;
      }
    }

  }

  return <></>;
}

export default withAITracking(reactPlugin, DataFetch);
