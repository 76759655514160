export const getTranslation = (businessData, type) => {
  const translationsArray = businessData && businessData[0]?.Translations;
  return translationsArray?.map((translation) => {
    return (
      type === translation.Type && (
        <h2
          dangerouslySetInnerHTML={{ __html: translation.Translation }}
        ></h2>
      )
    );
  });
};
