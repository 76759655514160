import { supportedLocales } from "./constants.helpers";
import moment from "moment";

export const updateLanguageCode = (selectedLanguageCode) => {
  try {
    return selectedLanguageCode === "zh-Hans"
      ? "zh-hans"
      : selectedLanguageCode === "zh-Hant"
        ? "zh-hant"
        : selectedLanguageCode === "pt-BR"
          ? "pt_br"
          : selectedLanguageCode;
  } catch (err) {
    console.log("Helper - catch - err :", err);
  }
};

/**
 * @function - Returns the most closetest language to i18nextLng.
 * @param {function} i18n - to change language.
 */

export const setLanguageCode = (i18n) => {

  const currentLanguageCode = (window.navigator.language).toLowerCase();

  if (currentLanguageCode) {
    switch (currentLanguageCode.toLowerCase()) {
      case 'zh-tw':
      case 'zh-hk':
        localStorage.setItem("i18nextLng", "zh-Hant");
        i18n.changeLanguage("zh-Hant");
        break;
      case 'zh-cn':
      case 'zh':
        localStorage.setItem("i18nextLng", "zh-Hans");
        i18n.changeLanguage("zh-Hans");
        break;
      case 'en-ca':
        localStorage.setItem("i18nextLng", "en-US");
        i18n.changeLanguage("en-US");
        break;
      default:
        if (currentLanguageCode.substring(0, 2).toLowerCase() === 'pt') {
          localStorage.setItem("i18nextLng", "pt-BR");
          i18n.changeLanguage("pt-BR");
        } else if (currentLanguageCode.substring(0, 2).toLowerCase() === 'zh') {
          localStorage.setItem("i18nextLng", "zh-Hans");
          i18n.changeLanguage("zh-Hans");
        } else {
          const shortenedLocale = supportedLocales.indexOf(currentLanguageCode) >= 0
            ? currentLanguageCode
            : currentLanguageCode?.substring(0, 2);

          i18n.changeLanguage(supportedLocales.indexOf(shortenedLocale) >= 0
            ? shortenedLocale
            : "en");
        }
        break;
    }
  }
}


/**
 * @function - Returns the warranty flag.
 * @param {function} warrantyEndDate.
 * @returns {boolean} 
 */

export const isUnderWarranty = (warrantyEndDate) => {
  const today = new Date();
  const warrantyEndDateFormated = new Date(warrantyEndDate);

  return moment(warrantyEndDateFormated).isSameOrAfter(today, "day");
}

/**
 * @function - Returns the is Right-To-Left flag.
 * @returns {boolean}
 */


export const isRightToLeft = () => {

  const selectedLanguage = localStorage.getItem("i18nextLng");

  const supportedLocalesDirRtl =
    supportedLocales.indexOf(selectedLanguage) >= 0
      ? selectedLanguage
      : selectedLanguage?.substring(0, 2);


  return supportedLocalesDirRtl.match(/^(ar|he|fa)$/) && true;

}


/**
 * @function - Returns the model name array.
 * @param {array} businessData.
 * @returns {array of model name}
 */

export const findModelName = (businessData) => {

  const TranslationsArr = businessData && businessData[0]?.TranslationsForEnglish;

  const MODEL_NAME = TranslationsArr?.filter(
    (item) =>
      (item.Type === "MODEL_NAME" && item.Translation) ||
      (item.Type === "PORTAL_CATALOG_LINE" && item.Translation)
  );

  return MODEL_NAME;

}
