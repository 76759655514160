import React, { useState } from "react";
import Arrow from "../../assets/icons/arrow-down.svg";
import { isRightToLeft } from "../../utils/helpers/functions.helper";
import "./accordion.scss";

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = useState(false);
  const rightToLeft = isRightToLeft();

  return (
    <>
      <div className="accordion">
        <button
          aria-expanded={isOpen ? true : false}
          aria-controls={title}
          aria-label={title}
          className={`accordionTitle ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
        >
          <span className={rightToLeft ? "titleRTL" : "title"}> {title}</span>
          <img
            className={rightToLeft ? "arrowRTL" : "arrow"}
            src={Arrow}
            alt=""
          />
        </button>
        <div className={`accordionItem ${!isOpen ? "collapsed" : ""}`} aria-hidden={isOpen ? false : true}>
          <div className="accordionContent">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Accordion;
