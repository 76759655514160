import { useState, useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import DemoLabel from "../../components/demoLabel/demoLabel";
import LogoWhite from "../../assets/icons/logoWhite.svg";
import warningIcon from "../../assets/icons/watch-id-attention.svg";
import "./associatePage.scss";
import CountDownCircleTimer from "../../components/countdownCircleTimer/countdownCircleTimer";
import DataLayer from "../../analytics/dataLayer";
import { setLanguageCode } from "../../utils/helpers/functions.helper";

const AssociatePage = ({ generatedCode, errorStatusCode, loading }) => {
  const { t, i18n } = useTranslation();
  const [contentFlag, setContentFlag] = useState(true);
  const [generatedCodeVal, setGeneratedCodeVal] = useState("");

  useEffect(() => {
    setLanguageCode(i18n);
  }, [i18n]);

  if (generatedCode) {
    setTimeout(() => {
      setContentFlag(false);
      setGeneratedCodeVal(t("sessionExpired"));
    }, 30000);
  }

  return (
    <>
      <DataLayer watchData="" status={errorStatusCode} loading={loading} />
      <DemoLabel />
      <div className="associateScreen">
        <img className="logoWhite" src={LogoWhite} alt={t("accessibility.logoImgAlt")} />

        <div className="textWrapper">
          <div className="bigTitle">
            {generatedCodeVal ? t("weAreSorry") : t("rolexPairingMode")}
          </div>
          <div className="smallTitle">
            {generatedCode && generatedCode?.DigitCode
              ? contentFlag
                ? t("otpContent")
                : t("expireContent")
              : "Oops, something went wrong. Please try again later."}
          </div>
          {generatedCode && generatedCode?.DigitCode ? (
            <>
              {generatedCodeVal ? (
                <>
                  <div className="smallTitle">
                    <b>{generatedCodeVal}</b>
                  </div>
                  <img className="warningIcon" src={warningIcon} alt="logo" />
                </>
              ) : generatedCode ? (
                <>
                  <div className="numbers">{generatedCode?.DigitCode}</div>
                  <CountDownCircleTimer />
                </>
              ) : (
                "Please try again later"
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <DataLayer pageBottom />
    </>
  );
};

export default withTranslation()(AssociatePage);
