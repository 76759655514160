import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  useTrackEvent,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./analytics/appInsights";
import ErrorPage from "./pages/errorPage/errorPage";
import AssociatePage from "./pages/associatePage/associatePage";
import Loading from "./pages/loadingPage/loadingPage";
import Product from "./pages/productPage/productPage";
import DataFetch from "./components/dataFetch/dataFetch";
import configData from "./config.json";
import Helmet from "./components/helmet/helmet";
import { isRightToLeft } from "./utils/helpers/functions.helper";

function Content() {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState("");
  const [errorStatusCode, setErrorStatusCode] = useState("");
  const [correlationId, setCorrelationId] = useState("");
  const [association, setAssociation] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [message, setMessage] = useState("");
  const [watchData, setWatchData] = useState(null);
  const [businessData, setbusinessData] = useState(null);
  const rightToLeft = isRightToLeft();

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = configData.analyticsUrl;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet />
      <div className={rightToLeft ? "rtl" : ""}>
        {loading ? (
          <>
            <Loading />
            <DataFetch
              setAssociation={setAssociation}
              setIsError={setIsError}
              setErrorStatusCode={setErrorStatusCode}
              setCorrelationId={setCorrelationId}
              setMessage={setMessage}
              watchData={watchData}
              setWatchData={setWatchData}
              setbusinessData={setbusinessData}
              setLoading={setLoading}
              association={association}
              setGeneratedCode={setGeneratedCode}
            />
          </>
        ) : isError && !association ? (
          <ErrorPage errorStatusCode={errorStatusCode} correlationId={correlationId} loading={loading} />
        ) : association ? (
          <AssociatePage errorStatusCode={errorStatusCode} generatedCode={generatedCode} loading={loading} />
        ) : (
          <Product
            rightToLeft={rightToLeft}
            watchData={watchData}
            businessData={businessData}
            loading={loading}
          />
        )}
      </div>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Content />} />
      </Routes>
    </BrowserRouter>
  );
}

export default withAITracking(reactPlugin, App);
