import React, { useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { supportedLocales } from "../../utils/helpers/constants.helpers";
import "./procedure.scss";

function Procedure({ rightToLeft, text }) {
  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const [isOverflow, setIsOverflowing] = useState(false);
  let selectedLanguageCode = "en";

  supportedLocales &&
    supportedLocales.length &&
    supportedLocales.forEach((languageCode) => {
      if (
        languageCode.toLowerCase() === selectedLanguage.toLowerCase() &&
        selectedLanguageCode.length == 2
      ) {
        selectedLanguageCode = selectedLanguage.toLowerCase();
      } else if (
        languageCode.substring(0, 2).toLowerCase() ===
        selectedLanguage.substring(0, 2).toLowerCase() &&
        selectedLanguageCode === "en"
      ) {
        selectedLanguageCode = selectedLanguage.substring(0, 2).toLowerCase();
      }
    });

  const urlLink =
    selectedLanguageCode === "en"
      ? "https://www.rolex.com/watch-care-and-service/the-rolex-servicing-procedure"
      : `https://www.rolex.com/${selectedLanguageCode}/watch-care-and-service/the-rolex-servicing-procedure`;

  useEffect(() => {
    const element = document.querySelector(".procedureWrapperHeader");

    let curOverflow = element.style.overflow;

    if (!curOverflow || curOverflow === "visible") {
      element.style.overflow = "hidden";
    }

    setTimeout(() => {
      let isOverflowing =
        element.clientWidth < element.scrollWidth;
      element.style.overflow = curOverflow;
      setIsOverflowing(isOverflowing);
    }, 100)

  }, [])


  return (
    <>
      <div className="procedureWrapper">
        <div className={rightToLeft ? "blockRTL" : "block"}>
          <div className="procedureWrapperHeader" style={{ fontSize: isOverflow ? "17px" : "20px" }}>
            <h2>
              {text}
            </h2>
          </div>
          <a href={urlLink} title={t("accessibility.serviceCentreTitle")} target="_blank" rel="noreferrer" aria-label={t("discoverButton")}>{t("discoverButton")}</a>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(Procedure);
