const fireActionTriggerCustomEvent = (data) => {
    typeof document !== "undefined" &&
        document.dispatchEvent(
            new CustomEvent("event-action-trigger", { detail: data || {} })
        );
};

export const analytics_trigger_event = ({ eventName, data }) => {
    if (!eventName)
        throw new Error("Analytics_Trigger_Event: eventName is epmty");
    const detailsCustomEvent = { triggerName: eventName };
    Object.assign(detailsCustomEvent, data);
    fireActionTriggerCustomEvent(detailsCustomEvent);
};


/**
 * Export of Adobe Launch Trigger
 */

export const fireViewEndCustomEvent = () => {
    // const event = new CustomEvent("event-view-end");
    // const obj = document.querySelector("body");
    // obj.dispatchEvent(event);
}


export const fireViewStartCustomEvent = (data) => {
    const event = new CustomEvent('event-react-view-start', data);
    const obj = document.dispatchEvent(event);
}
