import React from "react";
import LanguageSwitcher from "../../components/languageSwitcher/languageSwitcher";
import DemoLabel from "../../components/demoLabel/demoLabel";
import CpoProduct from "./cpoProduct";
import PedigreeProduct from "./pedigreeProduct";
import DataLayer from "../../analytics/dataLayer";
import { findModelName } from "../../utils/helpers/functions.helper";
import "./productPage.scss";

function Product({ rightToLeft, watchData, businessData, loading }) {
  const isCpo = watchData?.IsCpo;
  const modelName = findModelName(businessData)
    ? findModelName(businessData)[0]?.Translation
    : "ROLEX";
  const familyName = businessData?.length ? businessData[0]?.TranslationsForEnglish?.filter((item) => item?.Type?.includes('FAMILY_NAME'))[0]?.Translation : "";

  return (
    <>
      <DataLayer
        modelName={modelName ? modelName.toLowerCase() : 'rolex'}
        familyName={familyName ? familyName.toLowerCase() : 'rolex'}
        watchData={watchData}
        status="200"
        loading={loading}
      />
      <DemoLabel />
      <LanguageSwitcher rightToLeft={rightToLeft} isCpo={isCpo} />
      {isCpo ? (
        <CpoProduct
          rightToLeft={rightToLeft}
          watchData={watchData}
          businessData={businessData}
        />
      ) : (
        <PedigreeProduct
          rightToLeft={rightToLeft}
          watchData={watchData}
          businessData={businessData}
        />
      )}
      <DataLayer pageBottom />
    </>
  );
}

export default Product;
