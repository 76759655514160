import React from "react";
import Procedure from "../../components/procedure/procedure";
import Services from "../../components/services/services";
import { displayGuarantee } from "./utils";
import { getTranslation } from "../../components/specifications/utils";
import LogoWhite from "../../assets/icons/logoWhite.svg";
import { isUnderWarranty } from "../../utils/helpers/functions.helper";

import { withTranslation, useTranslation } from "react-i18next";

function PedigreeProduct({ rightToLeft, watchData, businessData }) {
  const { t } = useTranslation();
  let watchNameFlag = false;

  const Translations =
    businessData && businessData.length && businessData[0].Translations;

  const serviceInfo =
    watchData?.Services && watchData?.Services.length
      ? watchData.Services[0]
      : "";

  Translations &&
    Translations.length &&
    Translations.filter(
      (item) =>
        (item.Type === "MODEL_NAME" && item.Translation
          ? (watchNameFlag = "MODEL_NAME")
          : "") || (item.Type === "PORTAL_CATALOG_LINE" && item.Translation
            ? (watchNameFlag === false ? watchNameFlag = "PORTAL_CATALOG_LINE" : '')
            : "")
    );

  return (
    <>
      <img className="logoWhite" src={LogoWhite} alt={t("accessibility.logoImgAlt")} />
      <div className="productPage">
        <div className="content">
          <div className="card">
            <div className="cardContent">
              <div className="watchName">
                {watchNameFlag === false ? (
                  <div>ROLEX</div>
                ) : (
                  getTranslation(
                    businessData,
                    watchNameFlag === "MODEL_NAME"
                      ? "MODEL_NAME"
                      : "PORTAL_CATALOG_LINE"
                  )
                )}
              </div>
              <div className="serialNr">
                {t("serialNr")} {watchData?.SerialNumber}
                {watchData?.Configuration?.Model ? (
                  <div className="referenceNr">
                    {t("reference")}
                    {watchData?.Configuration?.Model.substring(1)}
                  </div>
                ) : null}
              </div>
              {displayGuarantee(
                watchData?.WarrantyEndDate,
                t("underGuarantee"),
                t("serviceGuarantee"),
                t("monthYearFormat")
              )}

              {/* <div className="statusGuarantee">
                {t("serviceGuarantee")}{" "}
                {watchData?.WarrantyEndDate && (
                  <Moment format={t("dateFormat")}>
                    {watchData?.WarrantyEndDate}
                  </Moment>
                )}
              </div> */}
            </div>
          </div>
          {(serviceInfo && serviceInfo.ServiceType) ||
            (serviceInfo.WarrantyExclWpr &&
              serviceInfo.WarrantyExclWpr === "Y") ||
            (serviceInfo.WarrantyExclPrf &&
              serviceInfo.WarrantyExclPrf === "Y") ||
            (serviceInfo.WarrantyExclBra &&
              serviceInfo.WarrantyExclBra === "Y") ? (
            <Services services={watchData?.Services} />
          ) : (
            ""
          )}

          <Procedure rightToLeft={rightToLeft} text={t("procedure")} />

          {
            isUnderWarranty(watchData?.WarrantyEndDate) && watchData?.Services && watchData?.Services.length ?
              <div className="card">
                <p className="warrantyLegalNotice">
                  {t("warrantyLegalNotice")}
                </p>
              </div>
              : ""
          }
        
        </div>
      </div>
    </>
  );
}

export default withTranslation()(PedigreeProduct);
