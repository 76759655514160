import React, { useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { preferedLanguage } from "./preferedLanguage";
import Close from "../../assets/icons/close.svg";
import LanguageIcon from "../../assets/icons/language.svg";
import LogoColor from "../../assets/icons/logo-color.svg";
import LogoCpo from "../../assets/icons/logo-cpo-black.svg";
import "./languageSwitcher.scss";

const LanguageSwitcher = ({ rightToLeft, error, isCpo }) => {
  const { t, i18n } = useTranslation();
  const [isLanguagesOpen, setLanguagesOpen] = useState(false);
  const handleClick = () => {
    error ? setLanguagesOpen(!isLanguagesOpen) : window.location.reload();
  };
  // const languagesArray = [
  //   { name: "English", code: "en" },
  //   { name: "Français", code: "fr" },
  // ];

  const languagesArray = [
    { name: "English", code: "en" },
    { name: "Français", code: "fr" },
    { name: "English US", code: "en-US" },
    { name: "Deutsch", code: "de" },
    { name: "Italiano", code: "it" },
    { name: "简体中文", code: "zh-Hans" },
    { name: "繁體中文", code: "zh-Hant" },
    { name: "日本語", code: "ja" },
    { name: "Español", code: "es" },
    { name: "Português", code: "pt-BR" },
    { name: "한국어", code: "ko" },
    { name: "Русский", code: "ru" },
    { name: "عربي", code: "ar" },
    { name: "Türkçe", code: "tr" },
    { name: "Bahasa Indonesia", code: "id" },
    { name: "ภาษาไทย", code: "th" },
    { name: "فارسی", code: "fa" },
    { name: "Nederlands", code: "nl" },
    { name: "Tiếng Việt", code: "vi" },
    { name: "हिंदी", code: "hi" },
    { name: "Polski", code: "pl" },
    { name: "עברית", code: "he" },
    { name: "Bahasa Malaysia", code: "ms" },
  ];
  // const languagesArray = [
  //   { name: "English", code: "en" },
  //   { name: "Français", code: "fr" },
  //   { name: "Italiano", code: "it" },
  //   { name: "繁體中文", code: "zh-Hant" },
  //   { name: "Español", code: "es" },
  //   { name: "한국어", code: "ko" },
  //   { name: "عربي", code: "ar" },
  //   { name: "Bahasa Indonesia", code: "id" },
  //   { name: "فارسی", code: "fa" },
  //   { name: "Tiếng Việt", code: "vi" },
  //   { name: "Polski", code: "pl" },
  //   { name: "Bahasa Malaysia", code: "ms" },
  // ];
  // const languagesArray2 = [
  //   { name: "English US", code: "en-US" },
  //   { name: "Deutsch", code: "de" },
  //   { name: "简体中文", code: "zh-Hans" },
  //   { name: "日本語", code: "ja" },
  //   { name: "Português", code: "pt_br" },
  //   { name: "Русский", code: "ru" },
  //   { name: "Türkçe", code: "tr" },
  //   { name: "ภาษาไทย", code: "th" },
  //   { name: "Nederlands", code: "nl" },
  //   { name: "हिंदी", code: "hi" },
  //   { name: "עברית", code: "he" },
  // ];

  return (
    <>
      {
        !isLanguagesOpen ?
          <button
            id="changeLanguage"
            className={rightToLeft ? "langButtonRTL" : "langButton"}
            aria-expanded={isLanguagesOpen ? true : false}
            aria-controls={rightToLeft ? "sliderRTL" : "slider"}
            aria-label="menu"
            onClick={() => setLanguagesOpen(!isLanguagesOpen)}
            aria-hidden={!isLanguagesOpen ? false : true}
          >
            <img
              alt=""
              src={LanguageIcon}
            >
            </img>
          </button>
          : ""
      }
      <div className={!isLanguagesOpen ? "overlay" : "overlayShown"}></div>
      <div
        id={rightToLeft ? "sliderRTL" : "slider"}
        className={isLanguagesOpen ? "slide-in" : ""}
        aria-hidden={!isLanguagesOpen ? true : false}
      >
        <div className="navigation">
          {
            isLanguagesOpen ?
              <>
                <button
                  aria-expanded={isLanguagesOpen ? true : false}
                  aria-controls="closeButton"
                  aria-label="menu"
                  tabindex="-1"
                  className="closeButton"
                  onClick={() => setLanguagesOpen(!isLanguagesOpen)}
                >
                  <img
                    alt=""
                    src={Close}
                  ></img>
                </button>

                <img
                  className={isCpo ? "logoCpo" : "logoNav"}
                  src={isCpo ? LogoCpo : LogoColor}
                  alt={t("accessibility.logoImgAlt")}
                />
                <ul aria-labelledby="changeLanguage">
                  <li className="heading"><h5>{t("languages")}</h5></li>
                  {languagesArray.map((language, index) => (
                    <li key={language.code} aria-setsize="23"
                      aria-posinset={index + 1}>
                      <button type="button"
                        aria-label={language.name}
                        className={
                          preferedLanguage() === language.code
                            ? "selectedLanguage"
                            : null
                        }
                        aria-current={
                          preferedLanguage() === language.code
                            ? true
                            : false
                        }
                        lang={language.code}
                        tabindex="-1"
                        onClick={() =>
                          i18n.changeLanguage(language.code) & handleClick()
                        }
                      >
                        {language.name}
                      </button>
                    </li>
                  ))}

                  {/* <li>
            {languagesArray2.map((language) => (
              <div
                className={
                  preferedLanguage() === language.code
                    ? "selectedLanguage"
                    : null
                }
                onClick={() =>
                  i18n.changeLanguage(language.code) & handleClick()
                }
              >
                {language.name}
              </div>
            ))}
          </li> */}
                </ul>
              </>
              : ""
          }
        </div>
      </div>
    </>
  );
};

export default withTranslation()(LanguageSwitcher);
