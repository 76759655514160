import React from "react";
import Exclusion from "../../components/services/exclusion";
import Procedure from "../../components/procedure/procedure";
import Moment from "react-moment";
import moment from "moment";
import { displayGuarantee } from "./utils";
import { getTranslation } from "../../components/specifications/utils";
import LogoCertified from "../../assets/icons/logo-cpo.svg";

import { withTranslation, useTranslation } from "react-i18next";

function CpoProduct({ rightToLeft, watchData, businessData }) {
  const { t } = useTranslation();
  let watchNameFlag = false;

  const Translations =
    businessData && businessData.length && businessData[0].Translations;

  Translations &&
    Translations.length &&
    Translations.filter(
      (item) =>
        (item.Type === "PORTAL_CATALOG_LINE" && item.Translation
          ? (watchNameFlag = "PORTAL_CATALOG_LINE")
          : "") ||
        (item.Type === "MODEL_NAME" && item.Translation
          ? (watchNameFlag = "MODEL_NAME")
          : "")
    );

  return (
    <>
      <img className="logoCpo" src={LogoCertified} alt="logo" />
      <div className="productPage">
        <div className="content">
          <div className="card">
            <div className="cardContent">
              <div className="serialNr">
                {t("serialNr")} {watchData?.SerialNumber}{" "}
                {watchData?.Configuration?.Rmc ? (
                  <>
                    | {t("reference")} {watchData?.Configuration?.Rmc}
                  </>
                ) : watchData?.Configuration?.Model ? (
                  <>
                    | {t("reference")} {watchData?.Configuration?.Model}
                  </>
                ) : null}
              </div>

              <div className="watchName">
                {watchNameFlag === false ? (
                  <div>ROLEX</div>
                ) : (
                  getTranslation(
                    businessData,
                    watchNameFlag === "PORTAL_CATALOG_LINE"
                      ? "PORTAL_CATALOG_LINE"
                      : "MODEL_NAME"
                  )
                )}
              </div>

              {displayGuarantee(
                watchData?.WarrantyEndDate,
                t("underGuarantee"),
                t("serviceGuarantee"),
                t("monthYearFormat")
              )}
            </div>
          </div>
          <div className="card">
            <div className="guarantee">
              <div className="heading">{t("cpoGuarantee")}</div>

              <Exclusion services={watchData?.Services} />

              <div className="heading">{t("cpoExpiryDate")}</div>

              {watchData?.WarrantyEndDate && (
                <Moment format={t("dateFormat")}>
                  {moment(watchData?.WarrantyEndDate).add(31, "days")}
                </Moment>
              )}
            </div>
          </div>
          <Procedure rightToLeft={rightToLeft} text={t("procedureCpo")} />
        </div>
      </div>
    </>
  );
}

export default withTranslation()(CpoProduct);
