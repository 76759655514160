import { useEffect } from "react";
import { fireViewStartCustomEvent, fireViewEndCustomEvent } from "./analytics";
import loadDataLayer from "./loadDataLayer";

const DataLayer = ({modelName, familyName, watchData, status, loading, pageBottom }) => {
  useEffect(() => {
    let time;
    if (!pageBottom) {
      fireViewStartCustomEvent({ detail: "" });
      loadDataLayer(modelName, familyName, watchData, status, loading);
    }

    if (pageBottom) {
      if (window?.__satelliteLoaded) {
        fireViewEndCustomEvent();
      } else {
        time = window.setInterval(() => {
          if (window?.__satelliteLoaded) {
            fireViewEndCustomEvent();
            clearInterval(time);
          }
        }, 300);
      }

      return () => clearInterval(time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default DataLayer;
